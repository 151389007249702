var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"section",class:'bg-'+_vm.data.SectionBackgroundColor,attrs:{"id":"section-icon-slider"}},[(_vm.data.BackgroundImage)?_c('div',{staticClass:"bg"},[_c('ResponsiveImageStrapi',{attrs:{"src":_vm.data.BackgroundImage}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container container-pad"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-24 col-md-24"},[_c('div',{staticClass:"content center"},[_c('div',{staticClass:"section-title align-center"},[_c('h2',[_vm._v(_vm._s(_vm.data.Headline))])])])])])]),_vm._v(" "),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content"},[_c('client-only',[_c('vue-glide',{attrs:{"options":{
					type: 'slider',
					//focusAt: '0',
					perView: 3,
					startAt: 0,
					bound: true,
					gap: 20,
					autoplay: 7000,
					hoverpause: true,
					rewind: false,
					touchDistance: 100000,
					dragDistance: 100000,
					breakpoints: {
						800: {
							perView: 2.5,
						},
					},
				}}},[_vm._l((_vm.data.IconSlider),function(icon,index){return [_c('vue-glide-slide',{key:index},[_c('div',{ref:"icon",refInFor:true,staticClass:"icon"},[(icon.Icon)?_c('div',{staticClass:"image"},[_c('ResponsiveImageStrapi',{attrs:{"src":icon.Icon}})],1):_vm._e()])])]}),_vm._v(" "),_c('template',{slot:"control"},[_c('button',{staticClass:"glide__arrow glide__arrow--left",attrs:{"data-glide-dir":"<","name":"previous"}},[_c('span',[_c('em',[_vm._v("Previous")])])]),_vm._v(" "),_c('button',{staticClass:"glide__arrow glide__arrow--right",attrs:{"data-glide-dir":">","name":"next"}},[_c('span',[_c('em',[_vm._v("Next")])])])]),_vm._v(" "),_c('div',{staticClass:"glide__bullets",attrs:{"slot":"control"},slot:"control"},[_c('div',{attrs:{"data-glide-el":"controls[nav]"}},[_vm._l((_vm.data.IconSlider),function(slide,index){return [_c('button',{key:index,staticClass:"glide__bullet",attrs:{"data-glide-dir":'='+index,"name":'slide '+index}},[_c('span',[_vm._v("Slide "+_vm._s(index))])])]})],2)])],2)],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-24"},[_c('div',{staticClass:"content"},[(_vm.data.Content)?_c('div',{staticClass:"p",domProps:{"innerHTML":_vm._s(_vm.data.Content)}},[_vm._v(_vm._s(_vm.data.Content))]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }