//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap'
import ScrollTrigger from '../../node_modules/gsap/dist/ScrollTrigger'
import ResponsiveImageStrapi from '@/components/modules/ResponsiveImageStrapi'

export default {
	props: {
		data: {
			type: Object,
			default: null,
		},
	},
	components: {
		ResponsiveImageStrapi
	},
			mounted() {
		this.$nextTick(this.Intro)
	},
	methods: {
		Intro() {
			setTimeout(() => {
				gsap.registerPlugin(ScrollTrigger)
				const page = this.$refs.section.closest('.page')
				const tl = gsap.timeline({
					scrollTrigger: {
						scroller: page,
						trigger: this.$refs.section,
						start: "top center",
						// markers: true
					}
				});
				tl.fromTo(this.$refs.icon, {
					autoAlpha: 0,
					y: 200
				}, {
					duration: 1.5,
					autoAlpha: 1,
					y: 0, 
					stagger:0.1
				});
			}, 0)
		},
	},
}
